import "./WorktimeModals.css"
import { Alert, FormControlLabel, InputLabel, Snackbar, Switch, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import { useEffect, useState } from "react";
import { CustomProvider, DatePicker } from "rsuite";
import de_DE from 'rsuite/locales/de_DE';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import { Delete, Info } from "@mui/icons-material";

function WorktimeModals(props) {
    const [correctionTimeHour, setCorrectionTimeHour] = useState("0");    
    const [correctionTimeMinute, setCorrectionTimeMinute] = useState("0");
    const [correctionTimeErrorHour, setCorrectionTimeErrorHour] = useState(false);
    const [correctionTimeErrorMinute, setCorrectionTimeErrorMinute] = useState(false);

    const [startResultValueHour, setStartResultValueHour] = useState("0");
    const [startResultValueMinute, setStartResultValueMinute] = useState("0");
    const [startResultValueErrorHour, setStartResultValueErrorHour] = useState(false);
    const [startResultValueErrorMinute, setStartResultValueErrorMinute] = useState(false)

    const [startResultChangeValueHour, setStartResultChangeValueHour] = useState("");
    const [startResultChangeValueMinute, setStartResultChangeValueMinute] = useState("");

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);


    const [monthlyTimeCreate, setMonthlyTimeCreate] = useState("");
    const [monthlyTimeError, setMonthlyTimeError] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        setCorrectionTimeHour("0");
        setCorrectionTimeMinute("0");
        setStartResultValueHour("0");
        setStartResultValueMinute("0");
        setStartResultChangeValueHour("");
        setStartResultChangeValueMinute("");

        setMonthlyTimeCreate("");
        setCorrectionTimeErrorHour(false);
        setCorrectionTimeErrorMinute(false);
        setStartResultValueErrorHour(false);
        setStartResultValueErrorMinute(false);
    }, [props.correctionOpen, props.newAccountOpen, props.settingsModalShow])

    const handleCorrectionChange = (e) => {
        const regex = /^[0-9\b]*$/;
        if (regex.test(e.target.value)) {
            if(e.target.id === "correctionTimeHour") setCorrectionTimeHour(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
            if(e.target.id === "correctionTimeMinute") setCorrectionTimeMinute(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
            if(e.target.id === "startResultChangeValueMinute") setStartResultChangeValueMinute(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
            if(e.target.id === "startResultValueMinute") setStartResultValueMinute(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));            
        }        
        setCorrectionTimeErrorHour(e.target.value === "");
        setCorrectionTimeErrorMinute(e.target.value === "");

        const regex2 = /^-?[0-9\b]*$/;
        if(regex2.test(e.target.value)){
            if(e.target.id === "startResultValueHour") setStartResultValueHour(e.target.value === "" ? "" : (e.target.value));
            if(e.target.id === "startResultChangeValueHour") setStartResultChangeValueHour(e.target.value === "" ? "" : (e.target.value));
        }
        setStartResultValueErrorHour(e.target.value === "");
        setStartResultValueErrorMinute(e.target.value === "");
    }

    function modalAddCorrection(){
        return (
            <Modal
                show={props.correctionOpen}
                onHide={props.correctionClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Überstunden ausgezahlt hinzufügen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.correctionClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <InputLabel>Ausgezahlte Überstunden eintragen:</InputLabel>
                    <div className="create-timeaccount-add-time">        
                        <TextField 
                            required 
                            value={correctionTimeHour}
                            error={correctionTimeErrorHour}
                            id="correctionTimeHour" 
                            label="Stunden" 
                            helperText={correctionTimeErrorHour ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                        <TextField 
                            required 
                            value={correctionTimeMinute}
                            error={correctionTimeErrorMinute}
                            id="correctionTimeMinute"  
                            label="Minuten" 
                            helperText={correctionTimeErrorMinute ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.correctionClose} />
                    <PrimaryButton text="Hinzufügen" onClick={props.createCorrectionPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function modalCloseWorktimeAccount(){
        return (
            <Modal
                show={props.closingShow}
                onHide={props.closingClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Arbeitszeitkonto schließen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.closingClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <CustomProvider locale={de_DE}>
                        <DatePicker required locale="de-DE" format="MMM yyyy" size="lg" placeholder="Endmonat" value={props.closingDatePickerValue} onChange={(selectedDate) => props.closingDatePickerOnChange(selectedDate)} shouldDisableDate={date => isBefore(date, new Date())} oneTap/>
                    </CustomProvider>                    
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.closingClose} />
                    <PrimaryButton text="Arbeitszeitkonto schließen" onClick={props.closeAccountPutRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    const handleMonthlyTimeCreateChange = (e) => {
        const regex = /^\d*,?\d{0,2}$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setMonthlyTimeCreate(e.target.value);
        }
        setMonthlyTimeError(e.target.value === "");
    }

    function modalAddNewWorktimeAccount(){
        let currentDate = new Date();
        let dateTwoYearsInFuture = new Date(currentDate.getFullYear() + 2, 0, 0);        

        return (
            <Modal
                show={props.newAccountOpen}
                onHide={props.newAccountClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Arbeitszeitkonto hinzufügen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.newAccountClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create-timeaccount-modal">
                        <CustomProvider locale={de_DE}>
                            <DatePicker required id="firstMonthPickerValue" format="MMM yyyy" size="lg" placeholder="Startmonat" value={props.newAccountDatePickerValue} onChange={(value) => props.newAccountDatePickerOnChange(value)} shouldDisableDate={date => isBefore(date, new Date()) || isAfter(date, dateTwoYearsInFuture)} oneTap/>
                        </CustomProvider>                        
                        <TextField 
                            value={monthlyTimeCreate} 
                            required 
                            error={monthlyTimeError} 
                            id="monthlyPlannedWorkTime" 
                            label="monatliche Soll-Zeit (in Std)" 
                            helperText={monthlyTimeError ? "Feld darf nicht leer sein" : false} 
                            onChange={handleMonthlyTimeCreateChange}
                        />
                    </div>
                    <InputLabel>Starttransferstunden eintragen:</InputLabel>
                    <div className="create-timeaccount-add-time">        
                        <TextField 
                            required 
                            value={startResultValueHour}
                            error={startResultValueErrorHour}
                            id="startResultValueHour" 
                            label="Stunden" 
                            helperText={startResultValueErrorHour ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                        <TextField 
                            required 
                            value={startResultValueMinute}
                            error={startResultValueErrorMinute}
                            id="startResultValueMinute"  
                            label="Minuten" 
                            helperText={startResultValueErrorMinute ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.newAccountClose} />
                    <PrimaryButton text="Arbeitszeitkonto eröffnen" onClick={props.newAccountPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function modalDeleteWorktimeAccount(){
        return (
            <Modal
                show={deleteModalOpen}
                onHide={handleSettingsDeleteClick}
                keyboard={true}
                backdrop="static"
                /* centered={true} */
            >
                <Modal.Header>
                    <Modal.Title>Arbeitszeitkonto löschen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleSettingsDeleteClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Möchten Sie dieses Arbeitszeitenkonto wirklich löschen?</p>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleSettingsDeleteClick} />
                    <PrimaryButton text="Arbeitszeitkonto löschen" onClick={props.deleteAccountDeleteRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    const handleSettingsDeleteClick = () => {
        props.settingsModalClose();
        setDeleteModalOpen(!deleteModalOpen);
    }

    const changeTab = (e, tabIndex) => {
        setSelectedTab(tabIndex);
    }

    function modalSettings(){
        return (
            <Modal
                show={props.settingsModalShow}
                onHide={props.settingsModalClose}
                keyboard={true}
                backdrop="static"
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Einstellungen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.settingsModalClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Tabs onChange={changeTab} value={selectedTab} >
                        <Tab label={"Standard"}/>
                        <Tab label={"Erweitert"}/>
                    </Tabs>
                    {selectedTab === 0 && (
                        <>
                            <InputLabel className="label-margin-top" >Transferstunden im nächsten Monat mit in Sollzeit einberechnen</InputLabel>
                            <div className="toggle-timeaccount-transfertime">
                                <FormControlLabel
                                    control={<Switch checked={props.transferHoursEnabledBoolean} onChange={(event)=> props.transferHoursEnabledOnChange(event)}/>}
                                    label="Transferstunden übernehmen"
                                />
                                <Tooltip title="Die Transferstunden werden in den nächsten Monat übertragen. 
                                Wichtig: Diese Änderung wirkt sich auch rückwirkend auf vergangene Monate aus.">
                                    <Info/>
                                </Tooltip>
                            </div>
                            <div className="delete-timeaccount-section">
                                <InputLabel>Arbeitszeitkonto permanent löschen</InputLabel>
                                <Button variant="danger" color="error" onClick={handleSettingsDeleteClick} ><Delete/> Löschen </Button>
                            </div>
                        </>
                    )}
                    {selectedTab === 1 && (
                        <>
                            <div className="create-timeaccount-add-time-label label-margin-top">
                                <InputLabel >Starttransferstunden eintragen:</InputLabel>
                                <Tooltip title="Die Starttransferstunden bestimmen bei einem neu erstelltem Arbeitszeitkonto, wieviele Stunden übernommen werden soll. 
                                Diese Werte können aus ihrer bisherigen Art und Weise Arbeitszeiten aufzunehmen genommen werden.">
                                    <Info/>
                                </Tooltip>
                            </div>
                            <div className="create-timeaccount-add-time">
                                <TextField 
                                    required 
                                    value={startResultChangeValueHour}
                                    id="startResultChangeValueHour" 
                                    label="Stunden"
                                    onChange={handleCorrectionChange}
                                />
                                <TextField 
                                    required 
                                    value={startResultChangeValueMinute}
                                    id="startResultChangeValueMinute"  
                                    label="Minuten"
                                    onChange={handleCorrectionChange}
                                />
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.settingsModalClose} />
                    <PrimaryButton text="Einstellungen übernehmen" onClick={props.settingsPutRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function datePickerErrorSnackbar() {
        return (
            <Snackbar open={props.datePickerError} autoHideDuration={6000} onClose={props.datePickerErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={props.datePickerErrorClose} severity="error" sx={{ width: '100%' }}>
                    Es wurde kein Monat ausgewählt.
                </Alert>
            </Snackbar>
        )
    }

    return(
        <>
            {modalAddCorrection()}
            {modalCloseWorktimeAccount()}
            {modalAddNewWorktimeAccount()}
            {modalDeleteWorktimeAccount()}
            {datePickerErrorSnackbar()}
            {modalSettings()}
        </>
    )
}

export default WorktimeModals;