import { useEffect, useState } from "react";
import "./WorkTimePlanning.css";
import axios from "axios";
import Logout from "../../../sharedComponents/Logout";
import LoadingSpinner from "../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Switch, Tooltip } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DateRangePicker } from "rsuite";
import { de } from "date-fns/locale";
import { FaClock } from "react-icons/fa";
import CustomSnackbar from "../../../sharedComponents/snackbar/CustomSnackbar";
import GetWeekNumber from "../../../sharedComponents/GetWeekNumber";
import PrimaryButton from "../../../sharedComponents/primaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import CancelButton from "../../../sharedComponents/cancelButton/CancelButton";
import parseDuration from "../../../sharedComponents/ParseDuration";
import { Info } from "@mui/icons-material";

let options = { day: '2-digit', month: '2-digit', year: 'numeric' };

function WorkTimePlanning() {

    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [employeesSchedulingTime, setEmployeesSchedulingTime] = useState([]);
    const [employeesCoreWorkTime, setEmployeesCoreWorkTime] = useState([]);
    const [currentDate, setCurrentDate] = useState(getMonday(sessionStorage.getItem("currentDate") ? sessionStorage.getItem("currentDate") : new Date()));
    const [today] = useState(new Date());

    const [targetHourTime, setTargetHourTime] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [localChanges, setLocalChanges] = useState({});
    const [snackbarConfig, setSnackbarConfig] = useState({ open: false, message: "", variant: "" });

    const [absentState, setAbsentState] = useState({});
    //const [absentReason, setAbsentReason] = useState({});
    
    const [switchEmployeeFunction, setSwitchEmployeeFunction] = useState(false)

    const [getAllTimePolicy, setGetAllTimePolicy] = useState([]);

    useEffect(() => {
        getEmployees();
    }, [refresh])

    function getEmployees() {
        axios.get('employee/getActive').then(results => {            
            setEmployees(results.data);      
            
            const monday = currentDate;
            const sunday = new Date(monday);
            sunday.setDate(monday.getDate() + 6);

            let targetHourArray = [];
            results.data.forEach((item) => {
                
                axios.get('workingTimeAccount/getAllForEmployeeWithId?id=' + item.id).then(results2 => {
                    const timeAccountData = results2.data[0];
                    if(timeAccountData !== undefined) targetHourArray.push(timeAccountData);
                })
            })
            setTargetHourTime(targetHourArray);

            axios.get('scheduling/getAllInDuration?start=' + monday.toISOString().slice(0, 10) + '&end=' + sunday.toISOString().slice(0, 10)).then(results => {
                setEmployeesSchedulingTime(results.data);
                
                axios.get('coreWorkingHoursPolicy/getAll').then(results => {
                    setEmployeesCoreWorkTime(results.data);
                    setIsLoading(false);
                })

                results.data.forEach((object) => {                    
                    if(object.absent){
                        const dateLocalString = new Date(object.date).toLocaleDateString('de-DE', options)
                        
                        setAbsentState(prevState => ({
                            ...prevState,
                            [`${object.employee_id}-${dateLocalString}`]: {
                                absent: object.absent, // Track checkbox state
                                comment: object.comment
                            }
                        }));
                    }
                    
                })

                
            }).catch(() => { Logout() })
        }).catch(() => { Logout()})

        getAutoPause();
    }

    function getAutoPause() {
        axios.get("minimumPauseTimePolicy/getAll").then((results) => {            
            let tempArray = [
            results.data[0].above_0_hours_constraint_in_minutes,
            results.data[0].above_1_hours_constraint_in_minutes,
            results.data[0].above_2_hours_constraint_in_minutes,
            results.data[0].above_3_hours_constraint_in_minutes,
            results.data[0].above_4_hours_constraint_in_minutes,
            results.data[0].above_5_hours_constraint_in_minutes,
            results.data[0].above_6_hours_constraint_in_minutes,
            results.data[0].above_7_hours_constraint_in_minutes,
            results.data[0].above_8_hours_constraint_in_minutes,
            results.data[0].above_9_hours_constraint_in_minutes,
            results.data[0].above_10_hours_constraint_in_minutes,
            results.data[0].above_11_hours_constraint_in_minutes,
            results.data[0].above_12_hours_constraint_in_minutes,
            results.data[0].above_13_hours_constraint_in_minutes,
            results.data[0].above_14_hours_constraint_in_minutes,
            results.data[0].above_15_hours_constraint_in_minutes,
            ];
            setGetAllTimePolicy(tempArray);
        }).catch(() => {Logout()});
    }

    const isDatePickerDisabled = (employeeId, date) => {
        const rowState = absentState[`${employeeId}-${date}`];
        return rowState?.absent;
    };
    
    const handleCheckboxChange = (employeeId, date, scheduleId, event) => {
        const isChecked = event.target.checked;

        const dateString = date.toLocaleDateString('de-DE', options)
    
        setAbsentState(prevState => ({
            ...prevState,
            [`${employeeId}-${dateString}`]: {
                absent: isChecked, // Track checkbox state
                comment: "Abwesend",
            }
        }));
        
        handleLocalChange(
            employeeId, 
            date.toLocaleDateString('de-DE', options), 
            [new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 1)], 
            scheduleId, 
            isChecked,
            "Abwesend"
        );
    }

    const handleSelectChange = (employeeId, date, scheduleId, event) => {
        const newComment = event.target.value;

        const dateString = date.toLocaleDateString('de-DE', options)

        setAbsentState(prevState => ({
            ...prevState,
            [`${employeeId}-${dateString}`]: {
                absent: absentState[`${employeeId}-${dateString}`].absent,
                comment: newComment,
            }
        }));

        handleLocalChange(
            employeeId, 
            date.toLocaleDateString('de-DE', options), 
            [new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 1)], 
            scheduleId, 
            absentState[`${employeeId}-${dateString}`].absent,
            newComment
        );
    }

    const handleLocalChange = (employeeId, date, value, scheduleId, absent, comment) => {
        if (value === null) {
            if (scheduleId) {                
                setLocalChanges(prevState => ({
                    ...prevState,
                    [`${employeeId}-${date}`]: {
                        employee_id: employeeId,
                        date: date,
                        id: scheduleId,
                        toDelete: true,
                        comment: comment,
                        absent: absent,
                    }
                }))
            } else {
                setLocalChanges(prevState => {
                    const newState = { ...prevState };
                    delete newState[`${employeeId}-${date}`];
                    return newState;
                });
            }
        } else if (value[0] !== null && value[1] !== null) {
            const startTime = addZeroToTime(value[0].getHours()) + ":" + addZeroToTime(value[0].getMinutes());
            const endTime = addZeroToTime(value[1].getHours()) + ":" + addZeroToTime(value[1].getMinutes());

            if(scheduleId && absent === false && startTime === "00:00" && endTime === "00:01"){
                setLocalChanges(prevState => ({
                    ...prevState,
                    [`${employeeId}-${date}`]: {
                        employee_id: employeeId,
                        date: date,
                        id: scheduleId,
                        toDelete: true,
                        comment: comment,
                        absent: absent,
                    }
                }))
            }else if(!scheduleId && absent === false && startTime === "00:00" && endTime === "00:01"){
                delete localChanges[`${employeeId}-${date}`];
            } else {
                setLocalChanges(prevState => ({
                    ...prevState,
                    [`${employeeId}-${date}`]: {
                        employee_id: employeeId,
                        date: date,
                        start_time: startTime,
                        end_time: endTime,
                        id: scheduleId,
                        toDelete: false,
                        comment: comment,
                        absent: absent,
                    }
                }));
            }
            
            
        }
    }

    const handleSaveChanges = () => {
        const requests = Object.values(localChanges).map(change => {
            let dateArray = change.date.split(".");
            let date = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
            if (change.toDelete && change.id) {
                return axios.delete(`scheduling/delete?id=${change.id}`);
            } else if (change.id) {
                return axios.put('scheduling/update', {
                    id: change.id,
                    date: date,
                    start_time: change.start_time,
                    end_time: change.end_time,
                    strict_mode: true,
                    comment: change.comment, 
                    absent: change.absent
                });
            } else if (!change.toDelete) {                
                return axios.post('scheduling/create', {
                    employee_id: change.employee_id,
                    date: date,
                    start_time: change.start_time,
                    end_time: change.end_time,
                    strict_mode: true,
                    comment: change.comment,
                    absent: change.absent,
                });
            }
            return null;
        }).filter(request => request !== null);

        Promise.all(requests).then(() => {
            sessionStorage.setItem("currentDate", currentDate);
            window.location.reload();
            setSnackbarConfig({ open: true, message: "Änderungen erfolgreich gespeichert.", variant: "success" });
        }).catch(() => {
            setSnackbarConfig({ open: true, message: "Fehler beim Speichern der Änderungen.", variant: "error" });
        });
    };

    function addZeroToTime(time) {return time < 10 ? "0" + time : time;}

    function formatNegativeZero(value) {
        const roundedValue = Math.round(value);
        return roundedValue === 0 && value < 0 ? '-0' : roundedValue;
    }
      

    function createTable() {
        let dynamicTable = [];
        let dates = [];
        let startDate = currentDate;
        let totalDurations = Array(7).fill(0);
        let weeklyTotals = [];
        
        for (let i = 0; i < 7; i++) {
            let date = new Date(startDate);
            date.setDate(startDate.getDate() + i);
            dates.push(date);
        }

        employees.forEach((employee) => {
            const coreTime = employeesCoreWorkTime.find(coreTime => coreTime.id === employee.assigned_core_working_hours_policy_id);
            let weeklyTotalMinutes = 0;
    
            dates.forEach((datesObject) => {
                let foundSchedule = false;
                let startTime = '';
                let endTime = '';
    
                const dateWeekDay = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"][datesObject.getDay()];
    
                if (coreTime) {
                    const period = coreTime.core_working_hours_periods.find(period => period.day_of_week === dateWeekDay);
                    if (period) {
                        foundSchedule = true;
                        startTime = period.start_time.slice(0, 5);
                        endTime = period.end_time.slice(0, 5);
                    }
                }
                
                const schedule = employeesSchedulingTime.find(schedule => schedule.employee_id === employee.id && new Date(schedule.date).toLocaleDateString('de-DE', options) === datesObject.toLocaleDateString('de-DE', options));
    
                if (schedule && !schedule.absent) {
                    foundSchedule = true;
                    startTime = schedule.start_time.slice(0, 5);
                    endTime = schedule.end_time.slice(0, 5);
                }

                if(coreTime && schedule && schedule.absent){
                    startTime = "00:00";
                    endTime = "00:00";
                }
    
                if (foundSchedule) {
                    let initialStartDate = new Date(datesObject.setHours(parseInt(startTime.slice(0, 2)), parseInt(startTime.slice(3, 5)), 0, 0));
                    let initialEndDate = new Date(datesObject.setHours(parseInt(endTime.slice(0, 2)), parseInt(endTime.slice(3, 5)), 0, 0));
    
                    const duration = ((initialEndDate - initialStartDate) / (1000 * 60)); // calculate duration in minutes

                    const durationMinusPause = duration - getAllTimePolicy[Math.min(Math.floor(duration / 60), 15)]
                    
                    weeklyTotalMinutes += durationMinusPause; // Add the duration to the weekly total
                }
            });
    
            weeklyTotals.push(weeklyTotalMinutes); // Store the weekly total for this employee
        });

        dynamicTable.push(
            <>
                <thead>
                    <tr>
                        <th></th>
                        {dates.map((date, index) => (
                            <th key={index} className={date.getDate() === today.getDate() ? 'planning-table-today-header' : ''}>
                                {["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"][date.getDay()]}
                                <br />
                                {date.toLocaleDateString('de-DE', {month: "2-digit", day: "2-digit", year: "numeric"})}
                            </th>
                        ))}
                        <th className="worktime-table-weeklytotal">Summe <Tooltip title="Summe der Wochenstunden mit Pausenzeit schon abgezogen" ><Info/></Tooltip> Wochenstunden</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee, rowIndex) => {
                        const coreTime = employeesCoreWorkTime.find(coreTime => coreTime.id === employee.assigned_core_working_hours_policy_id);
                        
                        const targetTimeObject = targetHourTime.find(item => (employee.first_name === item.employee_first_name && employee.last_name === item.employee_last_name))
                        
                        let displayTime = "";
                        let targetTimeObjectMonth;
                        let objectInMinutes

                        if(targetTimeObject !== undefined) {
                            targetTimeObjectMonth = targetTimeObject.months.find(object => object.month === currentDate.toISOString().slice(0, 7))
                            
                            
                            if(targetTimeObjectMonth !== undefined){

                                const targetTime = parseDuration(targetTimeObjectMonth.target_working_hours);

                                if(targetTimeObjectMonth.transfer_from_last_month !== null){
                                    
                                    const targetAndTransferObject = {hours: targetTime.hours, minutes: targetTime.minutes}

                                    objectInMinutes = (targetAndTransferObject.hours * 60 + targetAndTransferObject.minutes) / 4.35;

                                    displayTime = Math.floor(objectInMinutes/60) + " Std " + Math.floor(objectInMinutes % 60) + " min";
                                }
                            }
                        }

                        return (
                            <tr key={rowIndex}>
                                <th scope="row">
                                    {employee.first_name} {employee.last_name} 
                                    <br/>
                                    {employee.employment_type !== "---" && switchEmployeeFunction && ( 
                                        <>
                                            <Tooltip title="Beschäftigung">
                                                <i>{employee.employment_type}</i>
                                            </Tooltip>
                                            <br/>
                                        </>
                                    )}
                                    {coreTime && (
                                        <>
                                            <Link className="link-standardregelung" to={"/home/settings/coretime/detailView?id=" + employee.assigned_core_working_hours_policy_id} target="_blank">Standardregelung</Link>
                                            <br/>
                                        </>
                                    )}
                                    <div className="target-working-time-display">
                                        <Tooltip title="geplante wöchentliche Stunden">
                                            <span>{displayTime}</span>
                                        </Tooltip>                                        
                                    </div>                                    
                                </th>
                                {dates.map((currentDate, colIndex) => {
                                    let foundSchedule = false;
                                    let startTime = '';
                                    let endTime = '';

                                    const dateWeekDay = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"][currentDate.getDay()];

                                    if (coreTime) {
                                        const period = coreTime.core_working_hours_periods.find(period => period.day_of_week === dateWeekDay);
                                        if (period) {
                                            foundSchedule = true;
                                            startTime = period.start_time.slice(0, 5);
                                            endTime = period.end_time.slice(0, 5);
                                        }
                                    }
                                    
                                    const schedule = employeesSchedulingTime.find(schedule => schedule.employee_id === employee.id && new Date(schedule.date).toLocaleDateString('de-DE', options) === currentDate.toLocaleDateString('de-DE', options));

                                    //!schedule.absent for not adding the duration of the saved absent objects (currently implemented with starttime 00:00 and endtime 00:01) 
                                    if (schedule && !schedule.absent) {
                                        foundSchedule = true;
                                        startTime = schedule.start_time.slice(0, 5);
                                        endTime = schedule.end_time.slice(0, 5);
                                    }

                                    if(coreTime && schedule && schedule.absent){
                                        startTime = "00:00"
                                        endTime = "00:00"
                                    }

                                    const localChange = localChanges[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`];
                                    if (localChange?.toDelete) {
                                        return (
                                            <td key={colIndex}>
                                                <div className="deleted-label">Geplante Zeit entfernt</div>
                                            </td>
                                        );
                                    }

                                    const isAbsent = isDatePickerDisabled(employee.id, currentDate.toLocaleDateString('de-DE', options));

                                    if (foundSchedule) {
                                        let initialStartDate = new Date(currentDate.setHours(parseInt(startTime.slice(0, 2)), parseInt(startTime.slice(3, 5)), 0, 0));
                                        let initialEndDate = new Date(currentDate.setHours(parseInt(endTime.slice(0, 2)), parseInt(endTime.slice(3, 5)), 0, 0));

                                        const duration = (initialEndDate - initialStartDate) / (1000 * 60); // calculate duration in minutes

                                        const durationMinusPause = duration - getAllTimePolicy[Math.min(Math.floor(duration / 60), 15)]

                                        totalDurations[colIndex] += durationMinusPause;

                                        return (
                                            <td key={colIndex}>
                                                <DateRangePicker 
                                                    defaultValue={isAbsent ? [,] : [initialStartDate, initialEndDate]} 
                                                    format="HH:mm" 
                                                    character="-" 
                                                    placeholder={absentState[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.comment}
                                                    locale={de} 
                                                    caretAs={FaClock} 
                                                    oneTap 
                                                    onChange={value => handleLocalChange(employee.id, currentDate.toLocaleDateString('de-DE', options), value, schedule?.id, false, "")}
                                                    cleanable={schedule !== undefined} 
                                                    disabled={isAbsent}
                                                />
                                                <div className="abwesend-checkbox">
                                                    <Tooltip title="Abwesendheit einstellen">
                                                        <FormControlLabel                                                             
                                                            control={                                                    
                                                                <Checkbox 
                                                                    defaultChecked={absentState[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.absent}
                                                                    onChange={(event) => handleCheckboxChange(employee.id, currentDate, schedule?.id, event)}
                                                            />} 
                                                            label="Abwesend"
                                                        />
                                                    </Tooltip>
                                                    {
                                                        localChanges[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.absent && 
                                                        <FormControl fullWidth>
                                                            <InputLabel>Grund</InputLabel>
                                                            <Select 
                                                                label="Grund" 
                                                                value={absentState[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.comment} 
                                                                onChange={(event) => handleSelectChange(employee.id, currentDate, schedule?.id, event)}
                                                            >
                                                                <MenuItem value={"Abwesend"}>Abwesend</MenuItem>
                                                                <MenuItem value={"Urlaub"}>Urlaub</MenuItem>
                                                                <MenuItem value={"Berufsschule"}>Berufsschule</MenuItem>
                                                                <MenuItem value={"Frei"}>Frei</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                    
                                                </div>   
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td key={colIndex} >
                                                <DateRangePicker                                                     
                                                    format="HH:mm" 
                                                    character="-" 
                                                    placeholder={isAbsent ? absentState[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.comment : (coreTime ? "Verboten" : "Erlaubt")} 
                                                    locale={de} 
                                                    caretAs={FaClock} 
                                                    oneTap 
                                                    onChange={value => handleLocalChange(employee.id, currentDate.toLocaleDateString('de-DE', options), value, false, false, "")}                                                     
                                                    className="empty-cell" 
                                                    disabled={isDatePickerDisabled(employee.id, currentDate.toLocaleDateString('de-DE', options))}
                                                />
                                                <div className="abwesend-checkbox">
                                                    <Tooltip title="Abwesendheit einstellen">
                                                        <FormControlLabel                                                             
                                                            control={                                                    
                                                                <Checkbox 
                                                                    defaultChecked={absentState[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.absent}
                                                                    onChange={(event) => handleCheckboxChange(employee.id, currentDate, schedule?.id, event)}
                                                            />} 
                                                            label="Abwesend"
                                                        />
                                                    </Tooltip>
                                                    {
                                                        localChanges[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.absent && 
                                                        <FormControl fullWidth>
                                                            <InputLabel>Grund</InputLabel>
                                                            <Select 
                                                                label="Grund" 
                                                                value={absentState[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`]?.comment} 
                                                                onChange={(event) => handleSelectChange(employee.id, currentDate, schedule?.id, event)}
                                                            >
                                                                <MenuItem value={"Abwesend"}>Abwesend</MenuItem>
                                                                <MenuItem value={"Urlaub"}>Urlaub</MenuItem>
                                                                <MenuItem value={"Berufsschule"}>Berufsschule</MenuItem>
                                                                <MenuItem value={"Frei"}>Frei</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </div>                                               
                                            </td>
                                        );
                                    }
                                })}
                                <td className="worktime-table-weeklytotal">
                                    {Math.floor(weeklyTotals[rowIndex] / 60)} Std {weeklyTotals[rowIndex] % 60} min
                                    {targetTimeObject !== undefined && targetTimeObjectMonth !== undefined && <div className="weekly-total-time">
                                        <Tooltip title="wöchentliche Stundendifferenz">
                                            {console.log(Math.sign(Math.round((Math.round(objectInMinutes) - weeklyTotals[rowIndex])/60)))}
                                            <span>
                                                Differenz: {formatNegativeZero((Math.round(objectInMinutes) - weeklyTotals[rowIndex]) / 60)} Std {Math.abs((Math.round(objectInMinutes) - weeklyTotals[rowIndex]) % 60)} min
                                            </span>
                                        </Tooltip>
                                    </div>}
                                </td>
                            </tr>
                        );
                    })}
                    <tr className="worktime-table-dailytotal" key={"lastRow"}>
                        <th>
                            <Tooltip title="Summe Tagesstunden minus Automatische Pause">
                                <span>Summe Tagesstunden</span>
                            </Tooltip>
                        </th>
                        {totalDurations.map((object, index) => {
                            return (
                                <td key={index}>
                                    {Math.floor(object /60)} Std {object % 60} min
                                </td>
                            )
                        })}
                        <th>
                            Insgesamt Woche: 
                            <br/>
                            {Math.floor(totalDurations.reduce((acc, curr) => acc + curr, 0) /60)} Std {totalDurations.reduce((acc, curr) => acc + curr, 0) % 60} min
                        </th>
                    </tr>
                </tbody>
            </>
        )

        return <>
            <table className="work-time-planning-table">
                {dynamicTable}
            </table>
        </>
            
    }

    function getMonday(date) {
        date = new Date(date);
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    const handleNextWeek = () => {
        let newWeekDate = getMonday(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7))
        newWeekDate.setHours(10, 0, 0, 0);
        setCurrentDate(newWeekDate);
        setIsLoading(true);
        setRefresh(!refresh);
    }

    const handleLastWeek = () => {
        let newWeekDate = getMonday(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7))
        newWeekDate.setHours(10, 0, 0, 0);
        setCurrentDate(newWeekDate);
        setIsLoading(true);
        setRefresh(!refresh);
    }

    function getButtons() {
        return (
            <>
                <IconButton aria-label="next" disabled={Object.keys(localChanges).length > 0} onClick={handleLastWeek}>
                    <ChevronLeftIcon />
                </IconButton>
                <p>KW {GetWeekNumber(currentDate)}</p>
                <IconButton aria-label="next" disabled={Object.keys(localChanges).length > 0} onClick={handleNextWeek}>
                    <ChevronRightIcon />
                </IconButton>
            </>
            
        )
    }

    function createTableOrLoadingSpinner() {
        if(isLoading) {
            return <LoadingSpinner />
        } else {
            return (
                createTable()
            )
        }
    }

    const handleTodayButtonClicked = () => {
        setCurrentDate(getMonday(new Date()));
        setIsLoading(true);
        setRefresh(!refresh);
    }

    return (
        <div className="work-time-planning-page">
            <div className="work-time-planning-header">
                <h1>Arbeitszeitplanung</h1>
                <div className="work-time-planning-today-button">
                    <PrimaryButton text="Heute" onClick={handleTodayButtonClicked}/>
                </div>
                <div className="work-time-planning-next-back-buttons">
                    {getButtons()}
                </div>
                <CancelButton text="Wochenplan herunterladen" onClick={() => window.print()}/>
            </div>
            <h3 className="not-visible-info red">Planung kann nur auf einem größeren Bildschirm stattfinden.</h3>
            <div className="planning-information-section">
                <p>Hier können alle Arbeitszeiten der Mitarbeitenden geplant werden. Die <span className="bold">Kernarbeitszeiten</span> werden <span className="bold">automatisch</span> eingefügt. Diese können hier für die geplanten Tage <span className="bold">überschrieben</span> werden.</p>
                <p><span className="red-color">Achtung:</span> Mitarbeitende können sich nur in den geplanten Zeiten einloggen.</p>
            </div>
            <div className="work-time-planning-save-button">
                <PrimaryButton text="Speichern" onClick={handleSaveChanges} disabled={Object.keys(localChanges).length === 0}/>
                <FormControlLabel
                    control={<Switch checked={switchEmployeeFunction} onChange={(event)=> setSwitchEmployeeFunction(event.target.checked)}/>}
                    label="Mitarbeiterfunktion einblenden"
                />            
            </div>
            
            {createTableOrLoadingSpinner()}
            <CustomSnackbar 
                variant={snackbarConfig.variant} 
                message={snackbarConfig.message} 
                open={snackbarConfig.open} 
                onClose={() => setSnackbarConfig({ ...snackbarConfig, open: false })} 
            />
        </div>
    )
}

export default WorkTimePlanning;